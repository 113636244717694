import Link from 'components/Link';
import './index.css';

const Footer = () => (
  <footer className="footer">
    <span className="footer__date">
      <Link secondary className="footer__link" href="/humans.txt" target="_self">
        {`© ${new Date().getFullYear()} Made with ❤️ by  Jerry Yao.`}
      </Link>
    </span>
  </footer>
);

export default Footer;
